import React, {useState, useEffect, useCallback} from 'react';
import {
    Button,
    Box,
    Text,
    Progress,
    VStack,
    useToast,
    ButtonGroup,
    IconButton,
    Select,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Input,
    InputGroup,
    InputRightElement
} from '@chakra-ui/react';
import {FaDropbox, FaSync} from 'react-icons/fa';

const DROPBOX_APP_KEY = process.env.REACT_APP_DROPBOX_APP_KEY;
const REDIRECT_URI = window.location.origin + window.location.pathname; // Ensure no hash or query params

const useLocalStorage = (key, initialValue = null) => {
    const [value, setValue] = useState(() => {
        const storedValue = localStorage.getItem(key);
        return storedValue ? storedValue : initialValue;
    });

    const setStoredValue = newValue => {
        setValue(newValue);
        if (newValue === null) {
            localStorage.removeItem(key);
        } else {
            localStorage.setItem(key, newValue);
        }
    };

    return [value, setStoredValue];
};

// Helper function to list all Dropbox files
const getAllDropboxFiles = async (accessToken, path) => {
    const response = await fetch('https://api.dropboxapi.com/2/files/list_folder', {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            path,
            recursive: true
        })
    });

    if (!response.ok) {
        throw new Error('Failed to list Dropbox files');
    }

    const data = await response.json();
    return data.entries;
};

// Helper function to create directories recursively
const createDirectoryRecursively = async (folderHandle, path) => {
    const parts = path.split('/').filter(part => part);
    let currentHandle = folderHandle;
    for (const part of parts) {
        currentHandle = await currentHandle.getDirectoryHandle(part, {create: true});
    }
    return currentHandle;
};

const CHUNK_SIZE = 8 * 1024 * 1024; // 8 MB

const uploadFileInChunks = async (accessToken, file, path) => {
    const totalSize = file.size;
    let offset = 0;
    let sessionId = null;

    while (offset < totalSize) {
        const chunk = file.slice(offset, offset + CHUNK_SIZE);
        const isLastChunk = offset + CHUNK_SIZE >= totalSize;

        const response = await fetch(
            `https://content.dropboxapi.com/2/files/upload_session/${sessionId ? 'append_v2' : 'start'}`,
            {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Dropbox-API-Arg': JSON.stringify({
                        close: isLastChunk,
                        cursor: sessionId ? {session_id: sessionId, offset} : undefined
                    }),
                    'Content-Type': 'application/octet-stream'
                },
                body: chunk
            }
        );

        if (!response.ok) {
            throw new Error('Failed to upload chunk');
        }

        if (!sessionId) {
            const data = await response.json();
            sessionId = data.session_id;
        }

        offset += CHUNK_SIZE;
    }

    await fetch('https://content.dropboxapi.com/2/files/upload_session/finish', {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Dropbox-API-Arg': JSON.stringify({
                cursor: {session_id: sessionId, offset: totalSize},
                commit: {path, mode: 'overwrite'}
            }),
            'Content-Type': 'application/octet-stream'
        }
    });
};

const DOWNLOAD_CHUNK_SIZE = 8 * 1024 * 1024; // 8MB chunks
const MAX_RETRIES = 3;
const TIMEOUT_MS = 30000;

// Replace existing downloadFileInChunks function
const downloadFileInChunks = async (accessToken, path, onProgress) => {
    let retries = 0;

    while (retries < MAX_RETRIES) {
        const controller = new AbortController();
        const timeout = setTimeout(() => controller.abort(), TIMEOUT_MS);

        try {
            const metadata = await fetch('https://api.dropboxapi.com/2/files/get_metadata', {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({path}),
                signal: controller.signal
            }).then(res => res.json());

            const fileSize = metadata.size;
            const chunks = [];
            let downloadedBytes = 0;

            const response = await fetch('https://content.dropboxapi.com/2/files/download', {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Dropbox-API-Arg': JSON.stringify({path})
                },
                signal: controller.signal
            });

            if (!response.ok) throw new Error(`Download failed: ${response.status}`);

            const reader = response.body.getReader();

            while (true) {
                const {done, value} = await reader.read();
                if (done) break;

                chunks.push(value);
                downloadedBytes += value.length;

                if (onProgress) {
                    onProgress(downloadedBytes, fileSize);
                }
            }

            clearTimeout(timeout);

            // Combine chunks into final blob
            return new Blob(chunks);
        } catch (err) {
            clearTimeout(timeout);

            if (err.name === 'AbortError') {
                console.log('Download timed out, retrying...');
                retries++;
                continue;
            }

            if (retries < MAX_RETRIES - 1) {
                console.log(`Download failed, retry ${retries + 1}/${MAX_RETRIES}`);
                retries++;
                await new Promise(resolve => setTimeout(resolve, 1000 * retries));
                continue;
            }

            throw err;
        }
    }

    throw new Error('Max retries reached');
};

const DropboxSync = ({folderHandle}) => {
    const [show, setShow] = useLocalStorage('dropboxSyncShow', false);
    const [syncStatus, setSyncStatus] = useState('idle');
    const [progress, setProgress] = useState(0);
    const [error, setError] = useState(null);
    const [accessToken, setAccessToken] = useLocalStorage('dropboxAccessToken');
    const [dropboxFolders, setDropboxFolders] = useState([]);
    const [selectedFolder, setSelectedFolder] = useLocalStorage('dropboxSelectedFolder', '');
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
    const cancelRef = React.useRef();
    const toast = useToast();

    // Parse access token from URL on mount
    useEffect(() => {
        if (!accessToken) {
            const hash = window.location.hash;
            if (hash) {
                const params = new URLSearchParams(hash.substring(1));
                const token = params.get('access_token');
                if (token) {
                    setAccessToken(token);
                    // Clean the URL by removing the fragment
                    window.history.replaceState({}, document.title, REDIRECT_URI);
                }
            }
        }
    }, [accessToken, setAccessToken]);

    const fetchFolders = async () => {
        if (!accessToken) return;
        try {
            // Create app folder if it doesn't exist
            await fetch('https://api.dropboxapi.com/2/files/create_folder_v2', {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    path: '/App'
                })
            }).catch(() => {}); // Ignore if exists

            const response = await fetch('https://api.dropboxapi.com/2/files/list_folder', {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    path: '/App',
                    recursive: false
                })
            });

            if (!response.ok) {
                throw new Error('Failed to list folders');
            }

            const data = await response.json();
            const folders = data.entries.filter(entry => entry['.tag'] === 'folder');
            setDropboxFolders(folders);
        } catch (err) {
            console.error(err);
            setError('Failed to fetch folders');
            toast({
                title: 'Error fetching folders',
                status: 'error',
                duration: 3000
            });
        }
    };

    const createNewFolder = async folderName => {
        if (!accessToken || !folderName) return;
        try {
            // Validate folder name
            if (folderName.includes('/')) {
                throw new Error('Folder name cannot contain "/"');
            }

            await fetch('https://api.dropboxapi.com/2/files/create_folder_v2', {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    path: `/App/${folderName}`
                })
            });

            await fetchFolders();
            toast({
                title: 'Folder created',
                status: 'success',
                duration: 3000
            });
        } catch (err) {
            console.error(err);
            setError('Failed to create folder');
            toast({
                title: 'Error creating folder',
                description: err.message,
                status: 'error',
                duration: 3000
            });
        }
    };

    const syncToDropbox = async (handle = folderHandle, path = '') => {
        if (!accessToken || !selectedFolder) {
            setError('Missing access token or selected folder');
            return;
        }

        setSyncStatus('uploading');
        let total = 0;
        let uploaded = 0;

        // First count total files for progress
        const countFiles = async h => {
            for await (const entry of h.values()) {
                if (entry.kind === 'file') {
                    total++;
                } else if (entry.kind === 'directory') {
                    const dirHandle = await h.getDirectoryHandle(entry.name, {create: false});
                    if (dirHandle) {
                        await countFiles(dirHandle);
                    }
                }
            }
        };

        try {
            await countFiles(handle);

            // Then upload files
            const uploadFiles = async (h, currentPath) => {
                for await (const entry of h.values()) {
                    if (entry.kind === 'file') {
                        try {
                            const fileHandle = await h.getFileHandle(entry.name);
                            const file = await fileHandle.getFile();

                            await uploadFileInChunks(
                                accessToken,
                                file,
                                `/App/${selectedFolder}${currentPath ? `/${currentPath}` : ''}/${entry.name}`
                            );

                            uploaded++;
                            setProgress((uploaded / total) * 100);
                        } catch (err) {
                            console.error(`Failed to upload ${entry.name}:`, err);
                            setError(`Failed to upload ${entry.name}`);
                            toast({
                                title: `Error uploading ${entry.name}`,
                                description: err.message,
                                status: 'error',
                                duration: 3000
                            });
                        }
                    } else if (entry.kind === 'directory') {
                        const newHandle = await h.getDirectoryHandle(entry.name, {create: false});
                        if (newHandle) {
                            await uploadFiles(newHandle, currentPath ? `${currentPath}/${entry.name}` : entry.name);
                        }
                    }
                }
            };

            await uploadFiles(handle, '');
            setSyncStatus('idle');
            setProgress(0);
            toast({
                title: 'Upload complete',
                status: 'success',
                duration: 3000
            });
        } catch (err) {
            console.error(err);
            setError('Failed to sync to Dropbox');
            setSyncStatus('idle');
            toast({
                title: 'Error during upload',
                description: err.message,
                status: 'error',
                duration: 3000
            });
        }
    };

    const sanitizeFileName = name => {
        return name.replace(/[<>:"/\\|?*]/g, '_'); // Replace invalid characters with '_'
    };

    const downloadFromDropbox = async () => {
        if (!accessToken || !selectedFolder || !folderHandle) {
            setError('Missing access token, selected folder, or folder handle');
            return;
        }

        setSyncStatus('downloading');
        try {
            const files = await getAllDropboxFiles(accessToken, `/App/${selectedFolder}`);
            let downloaded = 0;
            const total = files.filter(f => f['.tag'] === 'file').length;

            for (const entry of files) {
                const relativePath = entry.path_display.split(`/App/${selectedFolder}/`)[1] || '';

                if (entry['.tag'] === 'folder') {
                    if (relativePath) {
                        await createDirectoryRecursively(folderHandle, relativePath);
                    }
                    continue;
                }

                if (entry['.tag'] === 'file') {
                    try {
                        // Sanitize the file name and parent path
                        const pathParts = relativePath.split('/');
                        const fileName = sanitizeFileName(pathParts.pop());
                        const sanitizedPath = pathParts.map(sanitizeFileName).join('/');

                        // Get/create necessary parent folders
                        let currentHandle = folderHandle;
                        if (sanitizedPath) {
                            currentHandle = await createDirectoryRecursively(folderHandle, sanitizedPath);
                        }

                        // Update progress calculation to include bytes
                        const updateProgress = (bytes, total) => {
                            const percentage = (bytes / total) * 100;
                            setProgress(percentage);
                        };

                        const blob = await downloadFileInChunks(accessToken, entry.path_lower, updateProgress);

                        const fileHandle = await currentHandle.getFileHandle(fileName, {create: true});
                        const writable = await fileHandle.createWritable();

                        // Write in chunks
                        const chunkSize = DOWNLOAD_CHUNK_SIZE;
                        let offset = 0;

                        while (offset < blob.size) {
                            const chunk = blob.slice(offset, offset + chunkSize);
                            await writable.write(chunk);
                            offset += chunkSize;
                        }

                        await writable.close();
                        downloaded++;
                    } catch (err) {
                        console.error(`Failed to download ${entry.name}:`, err);
                        setError(`Failed to download ${entry.name}`);
                        toast({
                            title: `Error downloading ${entry.name}`,
                            description: err.message,
                            status: 'error',
                            duration: 3000
                        });
                    }
                }
            }

            setSyncStatus('idle');
            setProgress(0);
            toast({
                title: 'Download complete',
                status: 'success',
                duration: 3000
            });
        } catch (err) {
            console.error(err);
            setError('Failed to download files');
            setSyncStatus('idle');
            toast({
                title: 'Error during download',
                description: err.message,
                status: 'error',
                duration: 3000
            });
        }
    };

    useEffect(() => {
        if (accessToken) {
            fetchFolders();
        }
    }, [accessToken]);

    return (
        <>
            <ButtonGroup>
                <IconButton icon={<FaDropbox />} onClick={() => setShow(!show)} aria-label="Toggle Dropbox sync" />
            </ButtonGroup>

            {show && (
                <Box>
                    {!accessToken ? (
                        <Button
                            onClick={() => {
                                const authUrl = `https://www.dropbox.com/oauth2/authorize?client_id=${DROPBOX_APP_KEY}&response_type=token&redirect_uri=${encodeURIComponent(REDIRECT_URI)}`;
                                window.location.href = authUrl;
                            }}
                        >
                            Connect to Dropbox
                        </Button>
                    ) : (
                        <VStack spacing={4}>
                            <Button
                                onClick={() => {
                                    setAccessToken(null);
                                    setSelectedFolder('');
                                    toast({
                                        title: 'Disconnected from Dropbox',
                                        status: 'info',
                                        duration: 3000
                                    });
                                }}
                                size="sm"
                                colorScheme="red"
                                variant="outline"
                            >
                                Disconnect from Dropbox
                            </Button>

                            <Box width="100%">
                                <form
                                    onSubmit={e => {
                                        e.preventDefault();
                                        const name = e.target.folderName.value.trim();
                                        if (name) {
                                            createNewFolder(name);
                                            e.target.folderName.value = '';
                                        }
                                    }}
                                >
                                    <InputGroup>
                                        <Input name="folderName" placeholder="New folder name" />
                                        <InputRightElement>
                                            <Button type="submit" size="sm">
                                                Create
                                            </Button>
                                        </InputRightElement>
                                    </InputGroup>
                                </form>
                            </Box>

                            <Select
                                placeholder="Select Dropbox folder"
                                value={selectedFolder}
                                onChange={e => setSelectedFolder(e.target.value)}
                            >
                                {dropboxFolders.map(folder => (
                                    <option key={folder.id} value={folder.name}>
                                        {folder.name}
                                    </option>
                                ))}
                            </Select>

                            {selectedFolder && (
                                <VStack spacing={2} width="100%">
                                    <Button
                                        onClick={() => setIsConfirmOpen(true)}
                                        isLoading={syncStatus === 'downloading'}
                                        leftIcon={<FaSync />}
                                        width="100%"
                                    >
                                        Sync from Dropbox
                                    </Button>
                                    <Button
                                        onClick={() => syncToDropbox(folderHandle)}
                                        isLoading={syncStatus === 'uploading'}
                                        leftIcon={<FaSync />}
                                        colorScheme="blue"
                                        width="100%"
                                    >
                                        Upload to Dropbox
                                    </Button>
                                </VStack>
                            )}

                            {syncStatus !== 'idle' && (
                                <Box w="100%">
                                    <Text>{syncStatus === 'downloading' ? 'Downloading...' : 'Uploading...'}</Text>
                                    <Progress value={progress} />
                                </Box>
                            )}
                        </VStack>
                    )}
                </Box>
            )}

            <AlertDialog isOpen={isConfirmOpen} leastDestructiveRef={cancelRef} onClose={() => setIsConfirmOpen(false)}>
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader>Confirm Overwrite</AlertDialogHeader>
                        <AlertDialogBody>
                            This will overwrite your local files with the ones from Dropbox. Continue?
                        </AlertDialogBody>
                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={() => setIsConfirmOpen(false)}>
                                Cancel
                            </Button>
                            <Button
                                colorScheme="red"
                                onClick={() => {
                                    setIsConfirmOpen(false);
                                    downloadFromDropbox();
                                }}
                                ml={3}
                            >
                                Overwrite
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    );
};

export default DropboxSync;
