import React, {useState, useEffect} from 'react';
import {FaScroll, FaLock} from 'react-icons/fa';
import {IconButton, Tooltip, useColorModeValue} from '@chakra-ui/react';

const ScrollToActivityToggle = () => {
    const [isActive, setIsActive] = useState(window.scrollToActivity);
    const bgColor = useColorModeValue('blackAlpha.400', 'whiteAlpha.200');

    useEffect(() => {
        window.scrollToActivity = isActive;
    }, [isActive]);

    const toggleScrollToActivity = () => {
        setIsActive(prevState => !prevState);
    };

    return (
        <Tooltip
            label={isActive ? 'Auto-scrolling lyrics (click to lock)' : 'Lyrics locked (click to auto-scroll)'}
            placement="right"
            hasArrow
            bg="white"
            color="black"
            boxShadow="md"
            borderRadius="md"
            px={3}
            py={2}
        >
            <IconButton
                icon={isActive ? <FaScroll /> : <FaLock />}
                onClick={toggleScrollToActivity}
                aria-label={isActive ? 'Disable auto-scroll' : 'Enable auto-scroll'}
                position="fixed"
                top="20px"
                left="20px"
                zIndex={9999}
                size="lg"
                isRound
                variant="solid"
                bg={bgColor}
                color="white"
                opacity={0.5}
                _hover={{
                    opacity: 1,
                    transform: 'scale(1.1)',
                    boxShadow: '0 0 15px rgba(255, 255, 255, 0.3)'
                }}
                transition="all 0.3s ease"
            />
        </Tooltip>
    );
};

export default ScrollToActivityToggle;
