import { getFFMPEG, fetchFile, ffmpegOutputToProgress } from './getFFMPEG.js';
import { Muxer, StreamTarget } from 'mp4-muxer';

const renderWithWebCodecAPI = async (
    folderHandle,
    canvasRef,
    audioUrl,
    renderCanvas,
    duration,
    fps,
    scale = 1,
    setStatus,
    stopState,
    UPDATE_RATE = 12,
    FLUSH_THRESHOLD = 1000
) => {
    const { VideoEncoder, VideoFrame } = window;
    const startTime = performance.now();

    if (!audioUrl) {
        setStatus('No audio url provided, continuing without audio.');
    }

    const totalFrames = duration * fps;
    const outputFileName = 'output.mp4';

    let buffer = new Uint8Array(1024 * 1024); // Initial buffer size: 1MB
    let bufferPos = 0;

    let videoFileHandle;
    try {
        videoFileHandle = await folderHandle.getFileHandle(outputFileName);
        const videoFile = await videoFileHandle.getFile();
        const videoArrayBuffer = await videoFile.arrayBuffer();
        buffer = new Uint8Array(videoArrayBuffer); // Load the video file
        bufferPos = buffer.length;
    } catch (e) {
        console.error('Error checking if video is already rendered:', e);
    }

    videoFileHandle = await folderHandle.getFileHandle(outputFileName, { create: true });

    const appendToBuffer = chunk => {
        if (bufferPos + chunk.byteLength > buffer.length) {
            // Double the buffer size if necessary
            const newSize = Math.max(buffer.length * 2, bufferPos + chunk.byteLength);
            const newBuffer = new Uint8Array(newSize);
            newBuffer.set(buffer.subarray(0, bufferPos), 0);
            buffer = newBuffer;
        }
        buffer.set(new Uint8Array(chunk), bufferPos);
        bufferPos += chunk.byteLength;
    };

    window.clearFile = async () => {
        if (videoFileHandle) {
            await videoFileHandle.remove();
        }
    };

    if (bufferPos === 0) {
        setStatus('Rendering video...');

        const muxer = new Muxer({
            target: new StreamTarget({
                onData: (chunk, position) => {
                    appendToBuffer(chunk);
                },
                chunked: false
            }),
            video: {
                codec: 'avc',
                width: canvasRef.current.width,
                height: canvasRef.current.height,
                framerate: fps
            },
            fastStart: 'fragmented'
        });

        const encoder = new VideoEncoder({
            output: (chunk, metadata) => {
                muxer.addVideoChunk(chunk, metadata);
            },
            error: e => console.error('VideoEncoder error:', e)
        });

        encoder.configure({
            codec: 'avc1.640028',
            width: canvasRef.current.width,
            height: canvasRef.current.height,
            framerate: fps
        });

        let lastFrame = null;

        const writeBuffer = async i => {
            setStatus('Writing to disk...');
            const writable = await videoFileHandle.createWritable();
            await writable.write(buffer.subarray(0, bufferPos)); // Write only the used portion of the buffer
            await writable.close();

            const progressFileHandle = await folderHandle.getFileHandle('progress.txt', { create: true });
            const progressWritable = await progressFileHandle.createWritable();
            await progressWritable.write(new Blob([i.toString()]));
            await progressWritable.close();
        };

        const cleanUp = async () => {
            await encoder.flush();

            await muxer.finalize();

            await new Promise(resolve => {
                setTimeout(resolve, 5000); // Wait for muxer to finish
            });

            try {
                await encoder.close();
            } catch (e) {
                console.error('Error closing encoder:', e);
            }
        };

        const handleUnload = async () => {
            stopState.stop = true;
            await cleanUp();
        };

        window.addEventListener('beforeunload', handleUnload);

        try {
            for (let i = 0; i < totalFrames; i++) {
                if (stopState.stop) {
                    setStatus('Rendering stopped.');
                    break;
                }

                await renderCanvas(i / fps, true, true);
                const videoFrame = new VideoFrame(canvasRef.current, {
                    timestamp: (i * 1000000) / fps,
                    duration: 1000000 / fps
                });
                await encoder.encode(videoFrame);
                videoFrame.close();

                if (i % FLUSH_THRESHOLD === 0) {
                    lastFrame = i;
                    setStatus('Flushing encoder...');
                    await encoder.flush();
                }

                if (i % UPDATE_RATE === 0) {
                    const elapsed = (performance.now() - startTime) / 1000;
                    const speedup = i / fps / elapsed;
                    const estimatedTime = elapsed / (i / totalFrames) - elapsed;
                    setStatus(
                        `Rendering frame: ${i} of ${totalFrames}... \nSpeedup: ${speedup.toFixed(2)}x \nEstimated time remaining: ${Math.round(estimatedTime)}s`
                    );
                    await new Promise(resolve => requestAnimationFrame(resolve));
                }
            }
            lastFrame = totalFrames;
            await cleanUp();

            // Save the buffer to disk after rendering is complete
            setStatus('Saving video to disk...');
            const videoFileHandle = await folderHandle.getFileHandle(outputFileName, { create: true });
            const writable = await videoFileHandle.createWritable();
            await writable.write(buffer.subarray(0, bufferPos)); // Write the accumulated buffer
            await writable.close();
            setStatus('Video saved to disk.');
        } catch (error) {
            console.error('Error during rendering:', error);
            setStatus('Error occurred during rendering: ' + error.message);
        }
    } else {
        setStatus('Video already rendered.');
    }

    try {
        setStatus('Encoding video...');
        const ffmpeg = await getFFMPEG(message => {
            ffmpegOutputToProgress('[ENCODING] ', message, duration, setStatus);
        });

        if (audioUrl) {
            setStatus('Writing audio to disk...');
            const audioFile = new Uint8Array(await fetchFile(audioUrl));
            await ffmpeg.writeFile('audio', audioFile);
        }
        const audioInput = audioUrl ? ['-i', 'audio'] : [];

        const videoFile = await videoFileHandle.getFile();
        const videoArrayBuffer = await videoFile.arrayBuffer();
        buffer = new Uint8Array(videoArrayBuffer); // Reload video file

        setStatus('Reading video...');
        await ffmpeg.writeFile('output.mp4', buffer.subarray(0, bufferPos));
        buffer = new Uint8Array();

        const args = [
            '-i',
            'output.mp4',
            ...audioInput,
            '-c:v',
            'copy',
            '-c:a',
            'aac',
            '-b:a',
            '320k',
            '-preset',
            'ultrafast',
            '-shortest',
            'output_encoded.mp4'
        ];

        setStatus('Running ffmpeg...');
        await ffmpeg.exec(args);

        setStatus('Cleaning up...');
        await ffmpeg.deleteFile('output.mp4');
        await ffmpeg.deleteFile('audio');

        setStatus('Reading encoded video...');
        const encodedFile = await ffmpeg.readFile('output_encoded.mp4');
        await ffmpeg.deleteFile('output_encoded.mp4');

        setStatus('Cleaning up...');
        const encodedVideoFileHandle = await folderHandle.getFileHandle('output_encoded.mp4', { create: true });
        const writableEncoded = await encodedVideoFileHandle.createWritable();
        await writableEncoded.write(encodedFile);
        await writableEncoded.close();

        buffer = encodedFile;
        setStatus('Video encoded.');
    } catch (e) {
        console.error('Error encoding video:', e);
        setStatus('Error occurred during encoding: ' + e.message);
    }

    setStatus('Finalizing video...');
    const videoUrl = URL.createObjectURL(new Blob([buffer], { type: 'video/mp4' }));
    const a = document.createElement('a');
    a.href = videoUrl;
    a.download = 'output.mp4';
    document.body.appendChild(a);
    a.click();

    window.dispatchEvent(new CustomEvent('update-video-url', { detail: videoUrl }));
    setStatus('Video ready for download.');

    return videoUrl;
};

export default renderWithWebCodecAPI;
